import { combineReducers } from "redux";

import timestampReducer from "./timestampReducer";
import userReducer from "./userReducer";
import serverStatusReducer from "./serverStatusReducer";

import chatUsersReducer from "./chatUsersReducer";
import chatRoomsReducer from "./chatRoomsReducer";
import chatReducer from "./chatReducer";

export default combineReducers({
    timestamp: timestampReducer,
    user: userReducer,
    serverStatus: serverStatusReducer,

    chatUsers: chatUsersReducer,
    chatRooms: chatRoomsReducer,
    chats: chatReducer,
});