import React from 'react';
import "./index.scss";

import { animateBox, closeModal } from "../../modules/componentAnimation";
import * as backendModule from "../../modules/backendModule";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';

import axios from 'axios';
import moment from 'moment';

export default function MonthlyPlans() {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [filters, setFilters] = React.useState([]);
    const [plans, setPlans] = React.useState([]);

    const getAllPlans = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/plans/getAllPlans`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setPlans(res.data);
            }
            else {
                setErrorMessage('Došlo je do greške...');
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        });
    }

    const addPlansToTable = () => {
        if (plans.data) {
            return plans?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Type}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'rgb(95, 132, 83)' }}>Are you sure?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='rgb(95, 132, 83)' value='YES' onClick={() => deletePlan(item.ID)} />
                                            <Button accent='rgb(95, 132, 83)' value='NO' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                },
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Prikaži ključne tačke", triggerDropdown: true, triggerData: e => {
                                    return (<div>
                                        {
                                            JSON.parse(item.KeyPoints).map((item, index) => {
                                                return <p>#{index + 1} {item}</p>
                                            })
                                        }
                                    </div>);
                                },
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Raspored", triggerDropdown: true, onClick: e => {
                                    animateBox(e, <PreviewModal days={JSON.parse(item.Days)} />);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Uredi", triggerDropdown: true, onClick: e => {
                                    animateBox(e, <EditPlanModal
                                        getAll={getAllPlans}
                                        days={JSON.parse(item.Days)}
                                        name={item.Name}
                                        type={item.Type}
                                        keyPoints={JSON.parse(item.KeyPoints)}
                                        ID={item.ID}
                                    />);
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    const deletePlan = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/plans/removePlan`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllPlans();
            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllPlans();
    }, [filters]);

    return (
        <div className='route__plans'>
            <div className='route__plans__header'>
                <div className='route__plans__header__button' onClick={(e) => animateBox(e, <AddNewPlanModal getAll={getAllPlans} />)}>
                    <img src='images/plus.svg' alt='' />
                    <p>Napravi novi plan</p>
                </div>
            </div>
            {!errorMessage && <div className='route__plans__body'>
                <FilteredCustomTable
                    id='table'
                    accent='#5f8453'
                    theme='light'
                    headers={['ID', 'Naziv', 'Tip', 'Uređeno']}
                    filters={[
                        { name: "ID", friendlyName: "ID", type: "number" },
                        { name: "Name", friendlyName: "Naziv", type: "string" },
                        { name: "Type", friendlyName: "Tip", type: "string" },
                    ]}
                    filterCB={f => setFilters(f)}
                    data={plans.data?.length > 0 ? addPlansToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                />
            </div>}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    )
}

const EditPlanModal = (props) => {
    const [emptyDays, setEmptyDays] = React.useState([]);
    const [days, setDays] = React.useState(props.days);
    const [tmp, setTmp] = React.useState(null);
    const [activeCalendar, setActiveCalendar] = React.useState('meals');
    const [spinner, setSpinner] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const nameRef = React.useRef(null);
    const typeRef = React.useRef(null);
    const keyPointsRef = React.useRef(null);

    let keyPointsPlainText = '';
    let delimiter = '\n';
    props.keyPoints?.map((item, index) => {
        if (index === 0) {
            delimiter = '';
        }
        else {
            delimiter = '\n';
        }

        keyPointsPlainText = keyPointsPlainText + delimiter + item;
    })

    const slide = (dir) => {
        if (activeCalendar === 'meals' && dir === 'right') {
            setActiveCalendar('sport');
        }
        else if (activeCalendar === 'sport' && dir === 'right') {
            setActiveCalendar('meditation');
        }
        else if (activeCalendar === 'meditation' && dir === 'left') {
            setActiveCalendar('sport');
        }
        else if (activeCalendar === 'sport' && dir === 'left') {
            setActiveCalendar('meals');
        }
    }

    const savePlan = (close) => {
        let i = 0;
        for (const day of days) {
            if (day.Meal) i++;
        }

        if (i.length < 31) {
            return setErrorMessage('Svi dani moraju imati dnevni plan!');
        }

        setSpinner(true);

        let tmpSports = [];
        let tmpMeditations = [];

        days.map(item => {
            item.Sport.map(item => {
                return tmpSports.push(item.ID ? item.ID : item);
            });
            item.Meditation.map(item => {
                return tmpMeditations.push(item.ID ? item.ID : item);
            });

            item.Sport = tmpSports;
            item.Meditation = tmpMeditations;

            tmpSports = [];
            tmpMeditations = [];
        });

        let keyPoints = keyPointsRef.current.value.split("\n");
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/plans/editPlan`,
            data: {
                ID: props.ID,
                Name: nameRef.current.value,
                Type: typeRef.current.value,
                KeyPoints: keyPoints,
                Days: days,
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === 'ok') {
                props.getAll();
                close();
            }
            else {
                setErrorMessage('Sva polja moraju biti popunjena!');
            }
        }).catch(() => {
            setErrorMessage('Server ne reagira!');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        for (let i = 1; i <= 31; i++) {
            setEmptyDays(prev => {
                return [
                    ...prev,
                    { Day: i, Meal: null, Sport: [], Meditation: [] }
                ]
            });
        }
    }, []);

    React.useEffect(() => {
        if (days.length === 31 && tmp) {
            const tmpDays = days;
            tmpDays?.map((item) => {
                if (item.Day === tmp.Day) {
                    item.Meal = tmp.Meal;
                    item.Sport = tmp.Sport;
                    item.Meditation = tmp.Meditation;
                }
            });
            setDays(tmpDays);
        }
    }, [tmp]);

    return <div className='component__addNewPlanModal'>
        <div className='component__addNewPlanModal__wrapper'>
            <div onClick={(e) => animateBox(e, <PreviewModal days={days} emptyDays={emptyDays} />)}>
                <img className='component__addNewPlanModal__wrapper__hamburger' src='images/hamburger.svg' alt='' />
            </div>
            <img className='component__addNewPlanModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
            {activeCalendar === 'meals' || activeCalendar === 'sport' ? <img className='component__addNewPlanModal__wrapper__arrowright' src='images/arrowright.svg' alt='' onClick={() => slide('right')} /> : ''}
            {activeCalendar === 'meditation' || activeCalendar === 'sport' ? <img className='component__addNewPlanModal__wrapper__arrowleft' src='images/arrowright.svg' alt='' onClick={() => slide('left')} /> : ''}
            <input type='text' placeholder='Naziv' ref={nameRef} defaultValue={props.name} />
            <input type='text' placeholder='Tip' ref={typeRef} defaultValue={props.type} />
            <textarea placeholder='Ključne tačke (razdvojite sa novim redom)' ref={keyPointsRef} defaultValue={keyPointsPlainText} />
            {activeCalendar === 'meals' && <p>Postavi obroke: </p>}
            {activeCalendar === 'meals' && <div className='component__addNewPlanModal__wrapper__calendar'>
                {
                    days?.map((item, index) => {
                        return <div
                            style={{ background: item.Meal || tmp?.Meal && tmp.Day === index + 1 ? 'rgb(95, 132, 83)' : 'rgba(175, 175, 175, 0.788)' }}
                            onClick={
                                (e) => animateBox(e, <PreparedMealsModal
                                    day={item.Day}
                                    meal={item.Meal}
                                    meditation={item.Meditation}
                                    sport={item.Sport}
                                    set={setTmp} />)
                            }
                        >
                            {item.Day}
                        </div>
                    })
                }
            </div>}
            {activeCalendar === 'sport' && <p>Postavi sport: </p>}
            {activeCalendar === 'sport' && <div className='component__addNewPlanModal__wrapper__calendar'>
                {
                    days?.map((item, index) => {
                        return <div
                            style={{ background: item.Sport.length > 0 || tmp?.Sport?.length > 0 && tmp.Day === index + 1 ? 'rgb(95, 132, 83)' : 'rgba(175, 175, 175, 0.788)' }}
                            onClick={
                                (e) => animateBox(e, <AddedSportsModal
                                    day={item.Day}
                                    sport={item.Sport}
                                    meal={item.Meal}
                                    meditation={item.Meditation}
                                    setTmp={setTmp} />)
                            }
                        >
                            {item.Day}
                        </div>
                    })
                }
            </div>}
            {activeCalendar === 'meditation' && <p>Postavi meditaciju: </p>}
            {activeCalendar === 'meditation' && <div className='component__addNewPlanModal__wrapper__calendar'>
                {
                    days?.map((item, index) => {
                        return <div
                            style={{ background: item.Meditation.length > 0 || tmp?.Meditation?.length > 0 && tmp.Day === index + 1 ? 'rgb(95, 132, 83)' : 'rgba(175, 175, 175, 0.788)' }}
                            onClick={
                                (e) => animateBox(e, <AddedMeditationsModal
                                    day={item.Day}
                                    sport={item.Sport}
                                    meal={item.Meal}
                                    meditation={item.Meditation}
                                    setTmp={setTmp} />)
                            }
                        >
                            {item.Day}
                        </div>
                    })
                }
            </div>}
            {!spinner && <Button className='component__addNewPlanModal__wrapper__button' value='Sačuvaj' accent='#5f8453' onClick={() => { savePlan(props.onClose) }} />}
            {spinner && <Spinner color='#5f8453' align='center' />}
            {errorMessage && <p style={{ color: 'black', fontWeight: '400' }}>{errorMessage}</p>}
        </div>
    </div>
}

const AddNewPlanModal = (props) => {
    const [days, setDays] = React.useState([]);
    const [emptyDays, setEmptyDays] = React.useState([]);
    const [tmp, setTmp] = React.useState(null);
    const [activeCalendar, setActiveCalendar] = React.useState('meals');
    const [spinner, setSpinner] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const nameRef = React.useRef(null);
    const typeRef = React.useRef(null);
    const keyPointsRef = React.useRef(null);

    const slide = (dir) => {
        if (activeCalendar === 'meals' && dir === 'right') {
            setActiveCalendar('sport');
        }
        else if (activeCalendar === 'sport' && dir === 'right') {
            setActiveCalendar('meditation');
        }
        else if (activeCalendar === 'meditation' && dir === 'left') {
            setActiveCalendar('sport');
        }
        else if (activeCalendar === 'sport' && dir === 'left') {
            setActiveCalendar('meals');
        }
    }

    const addPlan = (close) => {
        let i = 0;
        for (const day of days) {
            if (day.Meal) i++;
        }

        if (i.length < 31) {
            return setErrorMessage('Svi dani moraju imati dnevni plan!');
        }

        setSpinner(true);

        let tmpSports = [];
        let tmpMeditations = [];
        days.map(item => {
            item.Sport.map(item => {
                return tmpSports.push(item.ID);
            });
            item.Meditation.map(item => {
                return tmpMeditations.push(item.ID);
            });

            item.Sport = tmpSports;
            item.Meditation = tmpMeditations;

            tmpSports = [];
            tmpMeditations = [];
        });

        let keyPoints = keyPointsRef.current.value.split("\n");
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/plans/addPlan`,
            data: {
                Name: nameRef.current.value,
                Type: typeRef.current.value,
                KeyPoints: keyPoints,
                Days: days,
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === 'ok') {
                props.getAll();
                close();
            }
            else {
                setErrorMessage('Sva polja moraju biti popunjena!');
            }
        }).catch(() => {
            setErrorMessage('Server ne reagira!');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        for (let i = 1; i <= 31; i++) {
            setDays(prev => {
                return [
                    ...prev,
                    { Day: i, Meal: null, Sport: [], Meditation: [] }
                ]
            });
            setEmptyDays(prev => {
                return [
                    ...prev,
                    { Day: i, Meal: null, Sport: [], Meditation: [] }
                ]
            });
        }
    }, []);

    React.useEffect(() => {
        if (days.length === 31) {
            const tmpDays = days;
            tmpDays.map((item) => {
                if (item.Day === tmp.Day) {
                    item.Meal = tmp.Meal;
                    item.Sport = tmp.Sport;
                    item.Meditation = tmp.Meditation;
                }
            });
            setDays(tmpDays);
        }
    }, [tmp]);

    return <div className='component__addNewPlanModal'>
        <div className='component__addNewPlanModal__wrapper'>
            <div onClick={(e) => animateBox(e, <PreviewModal days={days} emptyDays={emptyDays} />)}>
                <img className='component__addNewPlanModal__wrapper__hamburger' src='images/hamburger.svg' alt='' />
            </div>
            <img className='component__addNewPlanModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
            {activeCalendar === 'meals' || activeCalendar === 'sport' ? <img className='component__addNewPlanModal__wrapper__arrowright' src='images/arrowright.svg' alt='' onClick={() => slide('right')} /> : ''}
            {activeCalendar === 'meditation' || activeCalendar === 'sport' ? <img className='component__addNewPlanModal__wrapper__arrowleft' src='images/arrowright.svg' alt='' onClick={() => slide('left')} /> : ''}
            <input type='text' placeholder='Naziv' ref={nameRef} />
            <input type='text' placeholder='Tip' ref={typeRef} />
            <textarea placeholder='Ključne tačke (razdvojite sa novim redom)' ref={keyPointsRef} />
            {activeCalendar === 'meals' && <p>Postavi obroke: </p>}
            {activeCalendar === 'meals' && <div className='component__addNewPlanModal__wrapper__calendar'>
                {
                    days?.map((item, index) => {
                        return <div
                            style={{ background: item.Meal || tmp?.Meal && tmp.Day === index + 1 ? 'rgb(95, 132, 83)' : 'rgba(175, 175, 175, 0.788)' }}
                            onClick={
                                (e) => animateBox(e, <PreparedMealsModal
                                    day={item.Day}
                                    meal={item.Meal}
                                    meditation={item.Meditation}
                                    sport={item.Sport}
                                    set={setTmp} />)
                            }
                        >
                            {item.Day}
                        </div>
                    })
                }
            </div>}
            {activeCalendar === 'sport' && <p>Postavi sport: </p>}
            {activeCalendar === 'sport' && <div className='component__addNewPlanModal__wrapper__calendar'>
                {
                    days?.map((item, index) => {
                        return <div
                            style={{ background: item.Sport.length > 0 || tmp?.Sport?.length > 0 && tmp.Day === index + 1 ? 'rgb(95, 132, 83)' : 'rgba(175, 175, 175, 0.788)' }}
                            onClick={
                                (e) => animateBox(e, <AddedSportsModal
                                    day={item.Day}
                                    sport={item.Sport}
                                    meal={item.Meal}
                                    meditation={item.Meditation}
                                    setTmp={setTmp} />)
                            }
                        >
                            {item.Day}
                        </div>
                    })
                }
            </div>}
            {activeCalendar === 'meditation' && <p>Postavi meditaciju: </p>}
            {activeCalendar === 'meditation' && <div className='component__addNewPlanModal__wrapper__calendar'>
                {
                    days?.map((item, index) => {
                        return <div
                            style={{ background: item.Meditation.length > 0 || tmp?.Meditation?.length > 0 && tmp.Day === index + 1 ? 'rgb(95, 132, 83)' : 'rgba(175, 175, 175, 0.788)' }}
                            onClick={
                                (e) => animateBox(e, <AddedMeditationsModal
                                    day={item.Day}
                                    sport={item.Sport}
                                    meal={item.Meal}
                                    meditation={item.Meditation}
                                    setTmp={setTmp} />)
                            }
                        >
                            {item.Day}
                        </div>
                    })
                }
            </div>}
            {!spinner && <Button className='component__addNewPlanModal__wrapper__button' value='Dodaj' accent='#5f8453' onClick={() => { addPlan(props.onClose) }} />}
            {spinner && <Spinner color='#5f8453' align='center' />}
            {errorMessage && <p style={{ color: 'black', fontWeight: '400' }}>{errorMessage}</p>}
        </div>
    </div>
}

const AddedMeditationsModal = (props) => {
    const [preview, setPreview] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);

    const addWorkoutsToTable = () => {
        if (preview) {
            return preview?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.name}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, onClick: e => {
                                    props.setTmp({ Day: props.day, Meal: props.meal, Sport: props.sport, Meditation: preview.filter(m => m.ID !== item.ID) });
                                    setPreview(prev => prev.filter(m => m.ID !== item.ID));
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    React.useEffect(() => {
        if (props.meditation.length === 0) setSpinner(false);

        props.meditation.map((item, index) => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/workouts/getWorkoutByID`,
                data: {
                    ID: item.ID ? item.ID : item
                },
                ...backendModule.axiosConfig
            }).then(res => {
                setPreview(prev => { return [...prev, { ID: res.data.data.ID, name: res.data.data.Name }] })
            }).catch(() => {

            }).finally(() => {
                if (index === props.meditation.length - 1) setSpinner(false);
            });
        });
    }, []);

    return <div className='component__addedMeditationsModal'>
        <div className='component__addedMeditationsModal__wrapper'>
            <img src='images/close.svg' alt='' onClick={props.onClose} className='component__addedMeditationsModal__wrapper__close' />
            <div className='component__addedMeditationsModal__wrapper__header'>
                <Button
                    className='component__addedMeditationsModal__wrapper__header__button'
                    accent='rgb(95, 132, 83)'
                    value='DODAJ MEDITACIJU'
                    onClick={
                        (e) => animateBox(e, <MeditationModal
                            day={props.day}
                            sport={props.sport}
                            meal={props.meal}
                            meditation={props.meditation}
                            set={props.setTmp}
                            setPreview={setPreview}
                            preview={preview} />)
                    }
                />
            </div>
            {!spinner && <div className='component__addedMeditationsModal__wrapper_table'>
                <FilteredCustomTable
                    id='table'
                    accent='#5f8453'
                    theme='light'
                    headers={['Naziv']}
                    data={preview?.length > 0 ? addWorkoutsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                />
            </div>}
            {spinner && <Spinner color='rgb(95, 132, 83)' align='center' />}
        </div>
    </div>
}

const AddedSportsModal = (props) => {
    const [spinner, setSpinner] = React.useState(true);
    const [preview, setPreview] = React.useState([]);

    const addWorkoutsToTable = () => {
        if (preview) {
            return preview?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.name}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, onClick: e => {
                                    props.setTmp({ Day: props.day, Meal: props.meal, Sport: preview.filter(s => s.ID !== item.ID), Meditation: props.meditation });
                                    setPreview(prev => prev.filter(s => s.ID !== item.ID));
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    React.useEffect(() => {
        if (props.sport.length === 0) setSpinner(false);

        props.sport.map((item, index) => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/workouts/getWorkoutByID`,
                data: {
                    ID: item.ID ? item.ID : item
                },
                ...backendModule.axiosConfig
            }).then(res => {
                setPreview(prev => { return [...prev, { ID: res.data.data.ID, name: res.data.data.Name }] })
            }).catch(() => {

            }).finally(() => {
                if (index === props.sport.length - 1) setSpinner(false);
            });
        });
    }, []);

    return <div className='component__addedSportsModal'>
        <div className='component__addedSportsModal__wrapper'>
            <img src='images/close.svg' alt='' onClick={props.onClose} className='component__addedSportsModal__wrapper__close' />
            <div className='component__addedSportsModal__wrapper__header'>
                <Button
                    className='component__addedSportsModal__wrapper__header__button'
                    accent='rgb(95, 132, 83)'
                    value='DODAJ SPORT'
                    onClick={
                        (e) => animateBox(e, <SportModal
                            day={props.day}
                            sport={props.sport}
                            meal={props.meal}
                            meditation={props.meditation}
                            set={props.setTmp}
                            setPreview={setPreview}
                            preview={preview} />)
                    }
                />
            </div>
            {!spinner && <div className='component__addedSportsModal__wrapper_table'>
                <FilteredCustomTable
                    id='table'
                    accent='#5f8453'
                    theme='light'
                    headers={['Naziv']}
                    data={preview?.length > 0 ? addWorkoutsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                />
            </div>}
            {spinner && <Spinner color='rgb(95, 132, 83)' align='center' />}
        </div>
    </div>
}

const PreparedMealsModal = (props) => {
    const [preparedMeals, setPreparedMeals] = React.useState([]);
    const [filters, setFilters] = React.useState([]);

    const getAllPreparedMeals = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/preparedMeals/getAllPreparedMeals`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setPreparedMeals(res.data);
            }
            else {

            }
        }).catch(() => {

        });
    }

    const addPreparedMealsToTable = () => {
        if (preparedMeals.data) {
            return preparedMeals?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.PatientType}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Breakfast}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Lunch}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Dinner}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Odaberi", triggerDropdown: true, onClick: e => {
                                    props.set({ Day: props.day, Meal: item.ID, Sport: props.sport, Meditation: props.meditation });
                                    props.onClose();
                                },
                            },
                        ]
                    }
                ];
            });
        }
    }

    React.useEffect(() => {
        getAllPreparedMeals();
    }, [filters]);

    return <div className='component__preparedMealsModal'>
        <div className='component__preparedMealsModal__wrapper'>
            <img className='component__preparedMealsModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
            <FilteredCustomTable
                id='table'
                accent='#5f8453'
                theme='light'
                headers={['ID', 'Tip pacijenta', 'Doručak', 'Ručak', 'Večera', 'Uređeno']}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "number" },
                    { name: "PatientType", friendlyName: "Tip pacijenta", type: "string" },
                ]}
                filterCB={f => setFilters(f)}
                data={preparedMeals.data?.length > 0 ? addPreparedMealsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
            />
        </div>
    </div>
}

const SportModal = (props) => {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [filters, setFilters] = React.useState([]);
    const [workouts, setWorkouts] = React.useState([]);

    const getAllWorkouts = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/workouts/getAllWorkouts`,
            data: {
                filters: [
                    ...filters,
                    { name: 'Type', op: 'eq', value: 'sport' }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setWorkouts(res.data);
            }
            else {
                setErrorMessage('Došlo je do greške...');
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        });
    }

    const addWorkoutsToTable = () => {
        if (workouts.data) {
            return workouts?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Type}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Prikaži sliku", triggerDropdown: true, onClick: e => {
                                    window.open(`/#/image/${String(item.Image).replace(`${backendModule.backendURL}/staticContent/images/`, '')}`, '_blank');
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Opis", triggerDropdown: true, triggerData: e => {
                                    return (<div>
                                        <p>{item.Description ? item.Description : 'Nema opisa'}</p>
                                    </div>);
                                },
                            },
                            {
                                keyID: String(item.ID), type: "button", text: props.preview.filter(s => s.ID === item.ID).length > 0 ? 'Već dodano' : "Odaberi", triggerDropdown: true, onClick: e => {
                                    if (props.preview.filter(s => s.ID === item.ID).length === 0) {
                                        props.set({ Day: props.day, Meal: props.meal, Sport: [...props.preview, { ID: item.ID, name: item.Name }], Meditation: props.meditation });
                                        props.setPreview(prev => { return [...prev, { ID: item.ID, name: item.Name }] });
                                        props.onClose();
                                    }
                                },
                            },
                        ]
                    }
                ];
            });
        }
    }

    React.useEffect(() => {
        getAllWorkouts();
    }, [filters]);

    return <div className='component__sport'>
        {!errorMessage && <div className='component__sport__wrapper'>
            <img className='component__sport__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
            <FilteredCustomTable
                id='table'
                accent='#5f8453'
                theme='light'
                headers={['ID', 'Tip', 'Naziv', 'Uređeno']}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "number" },
                    { name: "Type", friendlyName: "Tip", type: "string" },
                    { name: "Name", friendlyName: "Naziv", type: "string" },
                ]}
                filterCB={f => setFilters(f)}
                data={workouts.data?.length > 0 ? addWorkoutsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
            />
        </div>}
        {errorMessage && <p>{errorMessage}</p>}
    </div>
}

const MeditationModal = (props) => {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [filters, setFilters] = React.useState([]);
    const [workouts, setWorkouts] = React.useState([]);

    const getAllWorkouts = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/workouts/getAllWorkouts`,
            data: {
                filters: [
                    ...filters,
                    { name: 'Type', op: 'eq', value: 'meditation' }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setWorkouts(res.data);
            }
            else {
                setErrorMessage('Došlo je do greške...');
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        });
    }

    const addWorkoutsToTable = () => {
        if (workouts.data) {
            return workouts?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Type}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Prikaži sliku", triggerDropdown: true, onClick: e => {
                                    window.open(`/#/image/${String(item.Image).replace(`${backendModule.backendURL}/staticContent/images/`, '')}`, '_blank');
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: props.preview.filter(m => m.ID === item.ID).length > 0 ? 'Već dodano' : "Odaberi", triggerDropdown: true, onClick: e => {
                                    if (props.preview.filter(m => m.ID === item.ID).length === 0) {
                                        props.set({ Day: props.day, Meal: props.meal, Sport: props.sport, Meditation: [...props.preview, { ID: item.ID, name: item.Name }] });
                                        props.setPreview(prev => { return [...prev, { ID: item.ID, name: item.Name }] });
                                        props.onClose();
                                    }
                                },
                            },
                        ]
                    }
                ];
            });
        }
    }

    React.useEffect(() => {
        getAllWorkouts();
    }, [filters]);

    return <div className='component__meditation'>
        {!errorMessage && <div className='component__meditation__wrapper'>
            <img className='component__meditation__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
            <FilteredCustomTable
                id='table'
                accent='#5f8453'
                theme='light'
                headers={['ID', 'Tip', 'Naziv', 'Uređeno']}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "number" },
                    { name: "Type", friendlyName: "Tip", type: "string" },
                    { name: "Name", friendlyName: "Naziv", type: "string" },
                ]}
                filterCB={f => setFilters(f)}
                data={workouts.data?.length > 0 ? addWorkoutsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
            />
        </div>}
        {errorMessage && <p>{errorMessage}</p>}
    </div>
}

const PreviewModal = (props) => {
    const [finalDays, setFinalDays] = React.useState(props.emptyDays ? props.emptyDays : []);
    const [spinner, setSpinner] = React.useState(false);

    React.useEffect(() => {
        setSpinner(true);
        if (finalDays.length === 0) {
            for (let i = 1; i <= 31; i++) {
                setFinalDays(prev => {
                    return [
                        ...prev,
                        { Day: i, Meal: null, Sport: [], Meditation: [] }
                    ]
                });
            }
        }
    }, [finalDays]);

    React.useEffect(() => {
        if (finalDays.length > 0) {
            props.days?.map((item, index) => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/day/getOneDay`,
                    data: {
                        PreparedMealID: item.Meal,
                        SportID: item.Sport,
                        MeditationID: item.Meditation
                    },
                    ...backendModule.axiosConfig,
                }).then(res => {
                    if (res.data.status === 'ok') {
                        finalDays[index].Meal = { Breakfast: res.data.data.Breakfast, Lunch: res.data.data.Lunch, Dinner: res.data.data.Dinner };
                        finalDays[index].Sport = res.data.data.Sports;
                        finalDays[index].Meditation = res.data.data.Meditations;
                    }
                    else {

                    }
                }).catch(() => {

                }).finally(() => {
                    if (index === props.days.length - 1) {
                        setTimeout(() => {
                            setSpinner(false);
                        }, 100)
                    }
                });
            });
        }
    }, [finalDays]);

    return <div className='component__previewModal'>
        <div className='component__previewModal__wrapper'>
            <img src='images/close.svg' alt='' onClick={props.onClose} />
            {
                !spinner ?
                    finalDays?.map(item => {
                        return <div>
                            <p><strong>Dan:</strong> {item.Day}</p>
                            <p><strong>Obroci:</strong> {item.Meal?.Breakfast}, {item.Meal?.Lunch}, {item.Meal?.Dinner}</p>
                            <p><strong>Sport: </strong>
                                {
                                    item.Sport?.map(item => {
                                        return ` ${item.name === undefined ? item : item.name}, `;
                                    })
                                }
                            </p>
                            <p><strong>Meditacija: </strong>
                                {
                                    item.Meditation?.map(item => {
                                        return ` ${item.name === undefined ? item : item.name}, `;
                                    })
                                }
                            </p>
                        </div>
                    })
                    : <Spinner color='#5f8453' />
            }
        </div>
    </div>
}