import React from 'react';
import "./index.scss";

import { animateBox } from "../../modules/componentAnimation";
import * as backendModule from "../../modules/backendModule";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';
import Dropdown from '../../components/customComponents/Dropdown';
import ViewPhotoModal from '../../components/ViewPhotoModal';

import axios from 'axios';
import moment from 'moment';

const workoutTypes = [
    { name: 'sport', value: 'sport' },
    { name: 'meditation', value: 'meditation' }
];

export default function Workouts() {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [filters, setFilters] = React.useState([]);
    const [workouts, setWorkouts] = React.useState([]);

    const getAllWorkouts = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/workouts/getAllWorkouts`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setWorkouts(res.data);
            }
            else {
                setErrorMessage('Došlo je do greške...');
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        });
    }

    const addWorkoutsToTable = () => {
        if (workouts.data) {
            return workouts?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Type}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'rgb(95, 132, 83)' }}>Are you sure?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='rgb(95, 132, 83)' value='YES' onClick={() => deleteWorkout(item.ID)} />
                                            <Button accent='rgb(95, 132, 83)' value='NO' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                },
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Prikaži sliku", triggerDropdown: true, onClick: e => {
                                    animateBox(e, <ViewPhotoModal src={String(item.Image).replace(`${backendModule.backendURL}/staticContent/images/`, '')} />);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Opis", triggerDropdown: true, triggerData: e => {
                                    return (<div>
                                        <p>{item.Description ? item.Description : 'Nema opisa'}</p>
                                    </div>);
                                },
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Uredi", triggerDropdown: true, onClick: e => {
                                    animateBox(e, <EditWorkoutModal curWorkout={
                                        {
                                            ID: item.ID,
                                            Type: item.Type,
                                            Name: item.Name,
                                            Description: item.Description
                                        }
                                    } getAll={getAllWorkouts} />);
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    const deleteWorkout = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/workouts/removeWorkout`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllWorkouts();
            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllWorkouts();
    }, [filters]);

    return (
        <div className='route__workouts'>
            <div className='route__workouts__header'>
                <div className='route__workouts__header__button' onClick={(e) => animateBox(e, <AddNewWorkoutModal getAll={getAllWorkouts} />)}>
                    <img src='images/plus.svg' alt='' />
                    <p>Dodaj novu vježbu</p>
                </div>
            </div>
            {!errorMessage && <div className='route__meals__body'>
                <FilteredCustomTable
                    id='table'
                    accent='#5f8453'
                    theme='light'
                    headers={['ID', 'Tip', 'Naziv', 'Uređeno']}
                    filters={[
                        { name: "ID", friendlyName: "ID", type: "number" },
                        { name: "Type", friendlyName: "Tip", type: "string" },
                        { name: "Name", friendlyName: "Naziv", type: "string" },
                    ]}
                    filterCB={f => setFilters(f)}
                    data={workouts.data?.length > 0 ? addWorkoutsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                />
            </div>}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    )
}

const AddNewWorkoutModal = (props) => {
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [workoutType, setWorkoutType] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [spinner, setSpinner] = React.useState(false);

    const nameRef = React.useRef(null);
    const descriptionRef = React.useRef(null);

    const addWorkout = (close) => {
        if (!selectedImage) {
            setErrorMessage('Molimo izaberite sliku!');
        }

        setSpinner(true);

        let data = {
            Name: nameRef.current.value,
            Description: descriptionRef.current.value,
            Image: selectedImage,
            Type: workoutType
        }

        let fd = new FormData();
        for (let key in data) {
            fd.append(key, data[key]);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/workouts/addWorkout`,
            data: fd,
            ...backendModule.axiosConfig,
            headers: {
                ...backendModule.axiosConfig.headers,
                'Content-Type': 'multipart/form-data'
            },
        }).then(res => {
            if (res.data.status === 'ok') {
                props.getAll();
                close();
            }
            else {
                setErrorMessage('Sva polja moraju biti popunjena!');
            }
        }).catch(() => {
            setErrorMessage('Server ne reagira!');
        }).finally(() => {
            setSpinner(false);
        });
    }

    return <div className='component__addNewWorkoutModal'>
        {!spinner && <div className='component__addNewWorkoutModal__wrapper'>
            <img className='component__addNewWorkoutModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
            <input type='text' placeholder='Naziv' ref={nameRef} />
            <textarea type='text' placeholder='Opis' ref={descriptionRef} />
            <Dropdown accent='#517047' inlinePlaceholder='Vrsta vježbe'
                data={workoutTypes}
                onChange={e => setWorkoutType(e?.value)}
            />
            <div className='component__addNewWorkoutModal__wrapper__imginput'>
                <input type="file" accept="image/*" id="imageInput" onChange={(e) => {
                    setSelectedImage(e.target.files[0]);
                }} />
                <label for="imageInput" class="image-button"><img src="images/imginput.svg" class="image-preview" />{selectedImage ? selectedImage.name : 'Izaberi sliku'}</label>
            </div>
            <Button className='component__addNewWorkoutModal__wrapper__button' style={{ fontWeight: '700' }} value='Dodaj' accent='#517047' onClick={() => addWorkout(props.onClose)} />
            {errorMessage && <p>{errorMessage}</p>}
        </div>}
        {spinner && <Spinner color='#517047' />}
    </div>
}

const EditWorkoutModal = (props) => {
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [workoutType, setWorkoutType] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [spinner, setSpinner] = React.useState(false);

    const nameRef = React.useRef(null);
    const descriptionRef = React.useRef(null);

    const saveWorkout = (close) => {
        setSpinner(true);

        let data = {
            ID: props.curWorkout?.ID,
            Name: nameRef.current.value,
            Description: descriptionRef.current.value,
            Image: selectedImage ? selectedImage : '',
            Type: workoutType
        }

        let fd = new FormData();
        for (let key in data) {
            fd.append(key, data[key]);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/workouts/editWorkout`,
            data: fd,
            ...backendModule.axiosConfig,
            headers: {
                ...backendModule.axiosConfig.headers,
                'Content-Type': 'multipart/form-data'
            },
        }).then(res => {
            if (res.data.status === 'ok') {
                props.getAll();
                close();
            }
            else {
                setErrorMessage('Sva polja moraju biti popunjena!');
            }
        }).catch(() => {
            setErrorMessage('Server ne reagira!');
        }).finally(() => {
            setSpinner(false);
        });
    }

    return <div className='component__addNewWorkoutModal'>
        {!spinner && <div className='component__addNewWorkoutModal__wrapper'>
            <img className='component__addNewWorkoutModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
            <input type='text' placeholder='Naziv' ref={nameRef} defaultValue={props.curWorkout?.Name} />
            <textarea type='text' placeholder='Opis' ref={descriptionRef} defaultValue={props.curWorkout?.Description} />
            <Dropdown accent='#517047' inlinePlaceholder='Vrsta vježbe'
                data={workoutTypes}
                onChange={e => setWorkoutType(e?.value)}
                selected={workoutTypes.map(item => item.value).indexOf(props.curWorkout?.Type)}
            />
            <div className='component__addNewWorkoutModal__wrapper__imginput'>
                <input type="file" accept="image/*" id="imageInput" onChange={(e) => {
                    setSelectedImage(e.target.files[0]);
                }} />
                <label for="imageInput" class="image-button"><img src="images/imginput.svg" class="image-preview" />{selectedImage ? selectedImage.name : 'Izaberi sliku'}</label>
            </div>
            <Button className='component__addNewWorkoutModal__wrapper__button' style={{ fontWeight: '700' }} value='Sačuvaj' accent='#517047' onClick={() => saveWorkout(props.onClose)} />
            {errorMessage && <p style={{color: 'black'}}>{errorMessage}</p>}
        </div>}
        {spinner && <Spinner color='#517047' />}
    </div>
} 