import React from 'react';
import "./index.scss";

import * as backendModule from "../../modules/backendModule";

export default function ViewPhotoModal(props) {
  return (
    <div className='component__viewPhotoModal'>
        <img src='images/close.svg' alt='' className='component__viewPhotoModal__close' onClick={props.onClose} />
        <img src={`${backendModule.backendURL}/StaticContent/Images/${props.src}`} alt='' />
    </div>
  )
}
