import React from 'react';
import "./index.scss";
import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

export default function Footer(props) {
    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    return (
        <div className='component__footer'>
            <div>
                <img src='images/logo.svg' alt='' />
                <p>
                    Preuzmite kontrolu nad svojim zdravljem i podignite svoj život uz NutriProgram
                </p>
                <span>
                    <img src='images/linkedin.svg' alt='' />
                    <img src='images/messenger.svg' alt='' />
                </span>
            </div>
            <div>
                <strong>Meni</strong>
                <p style={{display: !props.userInfo?.Flags?.isAdmin && props.isLogged ? 'block' : 'none'}} onClick={() => animateNavigate('/vasprogram')}>Program</p>
                <p onClick={() => { window.location.hash !== '#/' ? animateNavigate('/') : document.querySelector('.route__landingPage__about').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }) }}>O nama</p>
                <p onClick={() => { window.location.hash !== '#/' ? animateNavigate('/') : document.querySelector('.route__landingPage__contact').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }) }}>Kontaktirajte nas</p>
            </div>
            <div>
                <strong>Resources</strong>
                <p>Terms and conditions</p>
                <p>Privacy policy</p>
            </div>
            <div>
                <strong>Prijavite se za newsletter</strong>
                <div>
                    <input type='text' placeholder='Vaša email adresa' />
                    <button>Prijaviti se</button>
                </div>
                <p>Sva prava pridržana © 2023</p>
            </div>
        </div>
    )
}
