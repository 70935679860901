import React from 'react';
import "./index.scss";

import { animateBox } from "../../modules/componentAnimation";
import * as backendModule from "../../modules/backendModule";

import Spinner from '../../components/customComponents/Spinner';
import Button from "../../components/customComponents/Button";

import Footer from "../../components/Footer";

import axios from 'axios';
import moment from 'moment';
import 'moment/locale/bs'

export default function YourProgram(props) {
  const [curPlan, setCurPlan] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [spinner, setSpinner] = React.useState(true);
  const [daySpinner, setDaySpinner] = React.useState(true);
  const [dayError, setDayError] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState('day1');
  const [days, setDays] = React.useState([]);
  const [oneDay, setOneDay] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [intervalOffset, setIntervalOffset] = React.useState(0);
  const [checkedTasks, setCheckedTasks] = React.useState([]);
  const [checkedMeals, setCheckedMeals] = React.useState(0);
  const [checkedSports, setCheckedSports] = React.useState(0);
  const [checkedMeditations, setCheckedMeditations] = React.useState(0);

  const getAllActivities = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/userActivity/getAllActivities`,
      data: {
        PlanID: props.userInfo?.PlanID,
        UserID: props.userInfo?.ID,
        Day: intervalOffset + props.userInfo.Difference + offset + 1,
      },
      ...backendModule.axiosConfig
    }).then(res => {
      setCheckedTasks(res.data.data);
      if (res.data.data.length === 0) setCheckedTasks(['null']);
    }).catch(() => {

    });
  }

  const updateActivity = (userID, type, checkboxID, planID) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/userActivity/updateActivity`,
      data: {
        PlanID: planID,
        UserID: userID,
        Type: type,
        Day: intervalOffset + props.userInfo.Difference + offset + 1,
        CheckboxID: checkboxID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getAllActivities();
      }
    }).catch(() => {

    });
  }

  React.useEffect(() => {
    moment.locale('bs');

    if (moment(props.userInfo?.AccessExpires) < moment() && !props.userInfo?.Flags.isAdmin) {
      setErrorMessage('Vaš plan je istekao, obratite se na broj +38733922241');
    }
  }, []);

  React.useEffect(() => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/plans/getPlanByID`,
      data: {
        ID: props.userInfo.PlanID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setCurPlan(res.data);
      }
      else {
        setErrorMessage('Došlo je do greške...');
      }
    }).catch(() => {
      setErrorMessage('Problem sa serverom...');
    });
  }, []);

  React.useEffect(() => {
    if (days.length > 0) {
      setDaySpinner(true);
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/day/getOneDayUserPanel`,
        data: {
          MealID: days[intervalOffset + props.userInfo.Difference + offset]?.Meal,
          SportID: days[intervalOffset + props.userInfo.Difference + offset]?.Sport,
          MeditationID: days[intervalOffset + props.userInfo.Difference + offset]?.Meditation
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === 'ok') {
          setOneDay(res.data);
          setDayError(null);
        }
        else {
          setDayError('Došlo je do greške...');
        }
      }).catch(() => {
        setDayError('Problemi sa serverom...');
      }).finally(() => {
        setDaySpinner(false);
      });
    }
  }, [days, offset, intervalOffset]);

  React.useEffect(() => {
    getAllActivities();
  }, [offset, intervalOffset, oneDay]);

  React.useEffect(() => {
    setCheckedMeals(checkedTasks.filter(ct => ct.Type === 'meal'));
    setCheckedSports(checkedTasks.filter(ct => ct.Type === 'sport'));
    setCheckedMeditations(checkedTasks.filter(ct => ct.Type === 'meditation'));
  }, [checkedTasks])

  React.useEffect(() => {
    if (curPlan) {
      setDays(JSON.parse(curPlan?.data?.Days));
    }
  }, [curPlan]);

  React.useEffect(() => {
    if (curPlan?.data && checkedTasks.length && oneDay?.data) {
      setSpinner(false);
    }
  }, [curPlan, checkedTasks, oneDay]);

  return <>
    <div className='route__yourProgram'>
      {!spinner && !errorMessage && <div className='route__yourProgram__body'>
        <p className='route__yourProgram__body__programName'>{curPlan?.data?.Name}</p>
        <h1 className='route__yourProgram__body__heading'>Vaš program</h1>
        <div className='route__yourProgram__body__headInfo'>
          <div className='route__yourProgram__body__headInfo__left'>
            <p className='route__yourProgram__body__headInfo__left__text'><strong>Program {curPlan?.data?.Name}</strong> uključuje:</p>
            <br></br><br></br>
            <p className='route__yourProgram__body__headInfo__left__text'><strong>#1</strong> plan obroka</p>
            {
              (() => {
                for (const item of JSON.parse(curPlan?.data?.Days)) {
                  if (item.Sport.length > 0) {
                    return <p className='route__yourProgram__body__headInfo__left__text'><strong>#2</strong> sportske aktivnosti</p>
                  }
                }
              })()
            }
            {
              (() => {
                for (const item of JSON.parse(curPlan?.data?.Days)) {
                  if (item.Meditation.length > 0) {
                    return <p className='route__yourProgram__body__headInfo__left__text'><strong>#3</strong> meditaciju</p>
                  }
                }
              })()
            }
          </div>
          <div className='route__yourProgram__body__headInfo__right'>
            <p className='route__yourProgram__body__headInfo__right__text'>Slijediti program važno je iz nekoliko razloga:</p>
            <br></br><br></br>
            {
              JSON.parse(curPlan?.data?.KeyPoints).map((item, index) => {
                return <p className='route__yourProgram__body__headInfo__right__text'><strong>#{index + 1}</strong> {item}</p>
              })
            }
            <br></br><br></br>
            <p className='route__yourProgram__body__headInfo__right__text'>
              <strong>Ispod možete pronaći vaš program.</strong> Nakon što završite svaki dio, <strong>označite ga kvačicom</strong> kako biste jednostavno <strong>pratili svoj napredak.</strong>
            </p>
          </div>
        </div>
        <div className='route__yourProgram__body__wrapper'>
          <div className='route__yourProgram__body__wrapper__navigation'>
            <img src='images/navCalArrow.svg' alt='' className='route__yourProgram__body__wrapper__navigation__arrowLeft' style={{ opacity: intervalOffset || props.userInfo?.Difference > 0 && '1', pointerEvents: intervalOffset < 0 && 'none' }} onClick={() => { intervalOffset + props.userInfo?.Difference + 1 >= 7 ? setIntervalOffset(prev => { return prev - 7 }) : setIntervalOffset(prev => { return prev - props.userInfo?.Difference }) }} />
            <p className='route__yourProgram__body__wrapper__navigation__text'>{`${intervalOffset + props.userInfo?.Difference + 1} dan - ${intervalOffset + props.userInfo?.Difference + 7} dan`}</p>
            <img src='images/navCalArrow.svg' alt='' className='route__yourProgram__body__wrapper__navigation__arrowRight' style={{ opacity: intervalOffset + props.userInfo?.Difference + 7 < 31 ? '1' : '0', pointerEvents: intervalOffset + props.userInfo?.Difference + 7 < 31 ? '' : 'none' }} onClick={() => { setIntervalOffset(prev => { if (intervalOffset === 0 || intervalOffset % 7 === 0) { return prev + 7 } else { return prev + props.userInfo?.Difference } }) }} />
          </div>
          <div className='route__yourProgram__body__wrapper__calendar'>
            <div className='route__yourProgram__body__wrapper__calendar__nav'>
              <div style={{ borderBottom: activeTab === 'day1' ? '2px solid #517047' : '' }} onClick={() => { setActiveTab('day1'); setOffset(0) }}>
                <p>{moment().format('dddd')}</p>
              </div>
              <div style={{ borderBottom: activeTab === 'day2' ? '2px solid #517047' : '' }} onClick={() => { setActiveTab('day2'); setOffset(1) }}>
                <p>{moment().add(1, 'days').format('dddd')}</p>
              </div>
              <div style={{ borderBottom: activeTab === 'day3' ? '2px solid #517047' : '' }} onClick={() => { setActiveTab('day3'); setOffset(2) }}>
                <p>{moment().add(2, 'days').format('dddd')}</p>
              </div>
              <div style={{ borderBottom: activeTab === 'day4' ? '2px solid #517047' : '' }} onClick={() => { setActiveTab('day4'); setOffset(3) }}>
                <p>{moment().add(3, 'days').format('dddd')}</p>
              </div>
              <div style={{ borderBottom: activeTab === 'day5' ? '2px solid #517047' : '' }} onClick={() => { setActiveTab('day5'); setOffset(4) }}>
                <p>{moment().add(4, 'days').format('dddd')}</p>
              </div>
              <div style={{ borderBottom: activeTab === 'day6' ? '2px solid #517047' : '' }} onClick={() => { setActiveTab('day6'); setOffset(5) }}>
                <p>{moment().add(5, 'days').format('dddd')}</p>
              </div>
              <div style={{ borderBottom: activeTab === 'day7' ? '2px solid #517047' : '' }} onClick={() => { setActiveTab('day7'); setOffset(6) }}>
                <p>{moment().add(6, 'days').format('dddd')}</p>
              </div>
            </div>
            {!daySpinner && <div className='route__yourProgram__body__wrapper__calendar__meals'>
              <Button
                accent='#AABC8C'
                className='route__yourProgram__body__wrapper__calendar__meals__reportButton'
                value='Prijavi alergiju'
                onClick={(e) => animateBox(e, <ReportModal userInfo={props.userInfo} />)}
              />
              <h1>Blok 1. Prehrana</h1>
              <div className='route__yourProgram__body__wrapper__calendar__meals__wrapper'>
                {!dayError && <div className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block' style={{ boxShadow: checkedTasks.filter(t => t.CheckboxID === oneDay?.data?.Breakfast.ID).length > 0 ? '0px 0px 6px #8D9B72' : '' }}>
                  <img src={`${oneDay?.data?.Breakfast.Image}`} alt='' className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__img' />
                  <div className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__checkbox' onClick={() => updateActivity(props.userInfo?.ID, 'meal', oneDay?.data?.Breakfast.ID, props.userInfo?.PlanID)}>
                    <img style={{ display: checkedTasks?.filter(t => t.CheckboxID === oneDay?.data?.Breakfast.ID).length > 0 ? 'block' : 'none' }} src='images/checkbox.svg' alt='' className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__checkbox__tick' />
                  </div>
                  <p className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__name'>Doručak</p>
                  <p className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__description'>{oneDay?.data?.Breakfast.Name}</p>
                  <div className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__dishes' style={{ display: 'flex', padding: '5px', justifyContent: 'center', gap: '5px' }}>
                    {
                      oneDay?.data?.Breakfast.Ingredients
                    }
                  </div>
                  <p className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__calories'>Kalorije: {oneDay?.data?.Breakfast.Calories} kcal</p>
                  <span onClick={(e) => animateBox(e, <MealDetailsModal data={oneDay?.data?.Breakfast} type='Doručak' />)} className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__button'>POGLEDAJ DETALJNO</span>
                </div>}
                {!dayError && <div className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block' style={{ boxShadow: checkedTasks.filter(t => t.CheckboxID === oneDay?.data?.Lunch.ID).length > 0 ? '0px 0px 6px #8D9B72' : '' }}>
                  <img src={`${oneDay?.data?.Lunch.Image}`} alt='' className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__img' />
                  <div className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__checkbox' onClick={() => updateActivity(props.userInfo?.ID, 'meal', oneDay?.data?.Lunch.ID, props.userInfo?.PlanID)}>
                    <img style={{ display: checkedTasks?.filter(t => t.CheckboxID === oneDay?.data?.Lunch.ID).length > 0 ? 'block' : 'none' }} src='images/checkbox.svg' alt='' className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__checkbox__tick' />
                  </div>
                  <p className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__name'>Ručak</p>
                  <p className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__description'>{oneDay?.data?.Lunch.Name}</p>
                  <div className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__dishes' style={{ display: 'flex', padding: '5px', justifyContent: 'center', gap: '5px' }}>
                    {
                      oneDay?.data?.Lunch.Ingredients
                    }
                  </div>
                  <p className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__calories'>Kalorije: {oneDay?.data?.Lunch.Calories} kcal</p>
                  <span onClick={(e) => animateBox(e, <MealDetailsModal data={oneDay?.data?.Lunch} type='Doručak' />)} className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__button'>POGLEDAJ DETALJNO</span>
                </div>}
                {!dayError && <div id='dinner' className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block' style={{ boxShadow: checkedTasks.filter(t => t.CheckboxID === oneDay?.data?.Dinner.ID).length > 0 ? '0px 0px 6px #8D9B72' : '' }}>
                  <img src={`${oneDay?.data?.Dinner.Image}`} alt='' className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__img' />
                  <div className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__checkbox' onClick={() => updateActivity(props.userInfo?.ID, 'meal', oneDay?.data?.Dinner.ID, props.userInfo?.PlanID)}>
                    <img style={{ display: checkedTasks?.filter(t => t.CheckboxID === oneDay?.data?.Dinner.ID).length > 0 ? 'block' : 'none' }} src='images/checkbox.svg' alt='' className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__checkbox__tick' />
                  </div>
                  <p className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__name'>Večera</p>
                  <p className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__description'>{oneDay?.data?.Dinner.Name}</p>
                  <div className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__dishes' style={{ display: 'flex', padding: '5px', justifyContent: 'center', gap: '5px' }}>
                    {
                      oneDay?.data?.Dinner.Ingredients
                    }
                  </div>
                  <p className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__calories'>Kalorije: {oneDay?.data?.Dinner.Calories} kcal</p>
                  <span onClick={(e) => animateBox(e, <MealDetailsModal data={oneDay?.data?.Dinner} type='Večera' />)} className='route__yourProgram__body__wrapper__calendar__meals__wrapper__block__button'>POGLEDAJ DETALJNO</span>
                </div>}
              </div>
            </div>}
            {!daySpinner && <div className='route__yourProgram__body__wrapper__calendar__sport'>
              <h1>Blok 2. Sportska aktivnost</h1>
              <p className='route__yourProgram__body__wrapper__calendar__sport__text'>
                Važno je napomenuti da trajanje i intenzitet vježbi ovise o nivou spremnosti i zdravstvenog stanja svake osobe. Stoga, uvijek je najbolje konzultirati se sa stručnjakom prije početka bilo kojeg programa vježbanja.
              </p>
              {!dayError && <div className='route__yourProgram__body__wrapper__calendar__sport__wrapper'>
                {
                  oneDay?.data?.Sport.map((item) => {
                    return <div className='route__yourProgram__body__wrapper__calendar__sport__wrapper__block' style={{ boxShadow: checkedTasks.filter(t => t.CheckboxID === item.ID).length > 0 ? '0px 0px 6px #8D9B72' : '' }}>
                      <div className='route__yourProgram__body__wrapper__calendar__sport__wrapper__block__checkbox' onClick={() => updateActivity(props.userInfo?.ID, 'sport', item.ID, props.userInfo?.PlanID)}>
                        <img style={{ display: checkedTasks?.filter(t => t.CheckboxID === item.ID).length > 0 ? 'block' : 'none' }} src='images/checkbox.svg' alt='' className='route__yourProgram__body__wrapper__calendar__sport__wrapper__block__checkbox__tick' />
                      </div>
                      <img src={`${item.Image}`} alt='' />
                      <strong>{item.Name}</strong>
                      <p>{item.Description}</p>
                    </div>
                  })
                }
              </div>}
            </div>}
            {!daySpinner && <div className='route__yourProgram__body__wrapper__calendar__meditation'>
              <h1>Blok 3. Meditacija</h1>
              {!dayError && <div className='route__yourProgram__body__wrapper__calendar__meditation__wrapper'>
                {
                  oneDay?.data?.Meditation.map((item) => {
                    return <div className='route__yourProgram__body__wrapper__calendar__meditation__wrapper__block' style={{ boxShadow: checkedTasks.filter(t => t.CheckboxID === item.ID).length > 0 ? '0px 0px 6px #8D9B72' : '' }}>
                      <div className='route__yourProgram__body__wrapper__calendar__meditation__wrapper__block__checkbox' onClick={() => updateActivity(props.userInfo?.ID, 'meditation', item.ID, props.userInfo?.PlanID)}>
                        <img style={{ display: checkedTasks?.filter(t => t.CheckboxID === item.ID).length > 0 ? 'block' : 'none' }} src='images/checkbox.svg' alt='' className='route__yourProgram__body__wrapper__calendar__meditation__wrapper__block__checkbox__tick' />
                      </div>
                      <img src={`${item.Image}`} alt='' />
                      <strong>{item.Name}</strong>
                      <p>{item.Description}</p>
                    </div>
                  })
                }
              </div>}
            </div>}
            {!daySpinner && <div className='route__yourProgram__body__wrapper__calendar__tracking'>
              <h1>Vaš napredak</h1>
              <div className='route__yourProgram__body__wrapper__calendar__tracking__head'>
                <p className='route__yourProgram__body__wrapper__calendar__tracking__head__text'>
                  Ovdje možete da pratite i mjerite svoj uspjeh dok slijedite plan obroka, sportske vježbe i meditaciju. Praćenjem vašeg napretka, bit ćete u mogućnosti vidjeti pozitivne promjene u vašem zdravlju.
                </p>
                <p className='route__yourProgram__body__wrapper__calendar__tracking__head__text'>
                  <strong>Važno je redovno pratiti program</strong> kako biste postigli najbolje rezultate i smanjili rizik od bolesti. Uz predanost i posvećenost, možete poboljšati zdravlje i uživati ​​u sretnijem, zdravijem životu.
                </p>
              </div>
              <div className='route__yourProgram__body__wrapper__calendar__tracking__stats'>
                <div className='route__yourProgram__body__wrapper__calendar__tracking__stats__block'>
                  <strong>Blok 1. prehrana</strong>
                  <div className='route__yourProgram__body__wrapper__calendar__tracking__stats__block__content'>
                    <p><strong>{checkedMeals.length}</strong>/{!dayError ? '3' : '0'}</p>
                    <img src='images/mealsstats.svg' alt='' />
                  </div>
                  <div className='route__yourProgram__body__wrapper__calendar__tracking__stats__block__progressBar'>
                    <div
                      className='route__yourProgram__body__wrapper__calendar__tracking__stats__block__progressBar__fill'
                      style={{ width: `${100 / 3 * checkedMeals?.length}%` }}
                    >
                    </div>
                  </div>
                </div>
                <div className='route__yourProgram__body__wrapper__calendar__tracking__stats__block'>
                  <strong>Blok 2. Sportska aktivnost</strong>
                  <div className='route__yourProgram__body__wrapper__calendar__tracking__stats__block__content'>
                    <p><strong>{checkedSports.length}</strong>/{!dayError ? oneDay?.data?.Sport.length : 0}</p>
                    <img src='images/sportsact.svg' alt='' />
                  </div>
                  <div className='route__yourProgram__body__wrapper__calendar__tracking__stats__block__progressBar'>
                    <div
                      className='route__yourProgram__body__wrapper__calendar__tracking__stats__block__progressBar__fill'
                      style={{ width: `${100 / oneDay?.data?.Sport.length * checkedSports?.length}%` }}
                    >
                    </div>
                  </div>
                </div>
                <div className='route__yourProgram__body__wrapper__calendar__tracking__stats__block' id='item3'>
                  <strong>Blok 3. Meditacija</strong>
                  <div className='route__yourProgram__body__wrapper__calendar__tracking__stats__block__content'>
                    <p><strong>{checkedMeditations.length}</strong>/{!dayError ? oneDay?.data?.Meditation.length : 0}</p>
                    <img src='images/meditationstats.svg' alt='' />
                  </div>
                  <div className='route__yourProgram__body__wrapper__calendar__tracking__stats__block__progressBar'>
                    <div
                      className='route__yourProgram__body__wrapper__calendar__tracking__stats__block__progressBar__fill'
                      style={{ width: `${100 / oneDay?.data?.Meditation.length * checkedMeditations?.length}%` }}
                    >
                    </div>
                  </div>
                </div>
              </div>
              <div className='route__yourProgram__body__wrapper__calendar__tracking__head'>
                <p className='route__yourProgram__body__wrapper__calendar__tracking__head__text'>
                  <strong>Čestitamo</strong> što ste napravili prvi korak ka poboljšanju zdravlja srca s ovim programom! Slijedeći plan obroka, sportske vježbe i praksu meditacije, preuzimate kontrolu nad svojim zdravljem i smanjujete rizik od srčanih bolesti. Iako to možda neće uvijek biti lako, zapamtite da je svaki mali korak koji poduzmete prema cilju napredak.
                </p>
                <p className='route__yourProgram__body__wrapper__calendar__tracking__head__text'>
                  Kada se osjećate obeshrabreno ili bez motivacije, podsjetite sebe zašto ste počeli i pozitivne promjene koje ste već doživjeli. Okružite se podrškom prijatelja i obitelji i ne bojte se zatražiti pomoć kada vam zatreba. Zapamtite, <strong>svaki korak je važan</strong> i vrijedi uloženog truda. Hajde da to učinimo!
                </p>
              </div>
            </div>}
            {daySpinner && <Spinner align='center' color='#517047' />}
          </div>
        </div>
      </div>}
      {spinner && <Spinner align='center' color='#517047' />}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
    <Footer isLogged={props.isLogged} userInfo={props.userInfo} />
  </>
}

const MealDetailsModal = (props) => {
  return <div className='component__moreMealsModal'>
    <div className='component__moreMealsModal__wrapper'>
      <img src='images/close.svg' alt='' className='component__moreMealsModal__wrapper__close' onClick={props.onClose} />
      <div className='component__moreMealsModal__wrapper__top'>
        <img src={props.data?.Image} alt='' />
        <div className='component__moreMealsModal__wrapper__top__right'>
          <p className='component__moreMealsModal__wrapper__top__right__type'>{props.type}</p>
          <p className='component__moreMealsModal__wrapper__top__right__name'>{props.data.Name}</p>
          <p className='component__moreMealsModal__wrapper__top__right__description'>{props.data.Description}</p>
          <p className='component__moreMealsModal__wrapper__top__right__calories'>Kalorije: {props.data.Calories} kcal</p>
        </div>
      </div>
      <p className='component__moreMealsModal__wrapper__dishes'>
        Sastojci:
        {
          props.data?.Ingredients
        }
      </p>
    </div>
  </div>
}

const ReportModal = (props) => {
  const titleRef = React.useRef(null);
  const messageRef = React.useRef(null);
  const [succeed, setSucceed] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);

  const sendMessage = () => {
    if (!titleRef.current.value || !messageRef.current.value) return setErrorMessage('Sva polja moraju biti popunjena!');

    setSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/messages/addMessage`,
      data: {
        FromUserID: props.userInfo?.ID,
        Title: titleRef.current.value,
        Message: messageRef.current.value
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setSucceed(true);
      }
      else {
        setErrorMessage('Nešto je pošlo po zlu...');
      }
    }).catch(() => {
      setErrorMessage('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  return <div className='component__reportModal'>
    {!succeed && <div className='component__reportModal__wrapper'>
      <img onClick={props.onClose} className='component__reportModal__wrapper__close' src='images/close.svg' alt='' />
      <p>Naslov (alergija koju imate)</p>
      <input ref={titleRef} type='text' />
      <p>Poruka (opišite problem)</p>
      <textarea ref={messageRef}></textarea>
      {!spinner && <Button className='component__reportModal__wrapper__button' accent='#517047' value='Pošalji' onClick={() => sendMessage()} />}
      {spinner && <Spinner color='#517047' align='center' />}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>}
    {succeed && <div className='component__reportModal__wrapper'>
      <img onClick={props.onClose} className='component__reportModal__wrapper__close' src='images/close.svg' alt='' />
      <p>Hvala što ste prijavili alergiju, u naredna 24 sata će Vaš plan biti zamijenjen ili prilagođen Vama!</p>
    </div>}
  </div>
}