import "./index.scss";
import React from "react";

import Button from "../../components/customComponents/Button";
import Footer from "../../components/Footer";

const LandingPage = (props) => {
    const [message, setMessage] = React.useState(null);

    const aboutRef = React.useRef(null);
    const nameRef = React.useRef(null);
    const phoneRef = React.useRef(null);

    const sendCRMmessage = () => {
        if (nameRef.current.value !== '' && phoneRef.current.value !== '') {
           fetch(`https://backend.scale-crm.com:5000/api/leads/addLead`,
               {
                   method: 'POST',
                   headers: {
                     'Accept': 'application/json',
                     'Content-Type': 'application/json'
                   },
                   body: JSON.stringify({
                       token: 'prEsXeW0FKMvSvoqwRdwqTuuCeprZgHw1IZHN8OMEnSlXPdrjZqZYWtvh0YlEEyjpkWY3Nt6CJafMpvo6lSvDBJwCWzzgoyvb07z',
                       Name: 'Vitalize/NutriProgram Upit BA',
                       FirstLastName: nameRef.current.value,
                       PhoneNumber: phoneRef.current.value,
                       Country: 'BA'
                   })
    
               }).then((res) => {
                   setMessage('Hvala što ste nas kontaktirali!');
               }).catch(() => {})
       } else {
           document.querySelector('.msg').innerHTML = 'Molimo unesite vaše podatke'
       }
    }

    return <>
        <div className="route__landingPage">
            <div className="route__landingPage__hero">
                <div>
                    <small>Preuzmite kontrolu nad svojim zdravljem </small>
                    <h1>NutriProgram</h1>
                    <p>
                        Dobrodošli u naš NutriProgram, program za zdravu prehranu i životni stil! Naša misija je pomoći vam u postizanju optimalnog zdravlja i blagostanja kroz pravilnu prehranu i životni stil.
                        <br></br><br></br>
                        Naš tim stručnjaka za prehranu i wellness je osmislio ovaj program kako bismo vam pružili personalizirani plan prehrane i wellnessa koji se temelji na vašim ciljevima i potrebama. Bez obzira želite li izgubiti težinu, ojačati imunološki sustav, poboljšati zdravlje srca ili jednostavno osjećati se bolje u svom tijelu, naš NutriProgram pružit će vam alate i resurse koje trebate kako biste postigli svoje ciljeve.
                    </p>
                    <Button accent='#517047' className='aboutButton' value='O nama' onClick={() => { aboutRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }) }} />
                </div>
                <img src="images/hero.png" alt="" />
            </div>
            <div ref={aboutRef} className="route__landingPage__about">
                <img src={window.innerWidth > 435 ? "images/about.png" : "images/mobileabout.png"} alt="" />
                <div>
                    <h1>O nama</h1>
                    <p>
                        <strong>Naša misija</strong> je da vam pružimo alate i resurse koje vam trebaju da <strong>živite zdrav i sretan život</strong>. Vjerujemo da je zdravlje više od samo odsustva bolesti - radi se o njegovanju vašeg tijela, uma i duše kako biste mogli napredovati u svim područjima svog života.
                        <br></br><br></br>
                        U središtu našeg programa je <strong>naš plan obroka.</strong> Naš tim stručnjaka za prehranu pažljivo je osmislio ukusne i hranjive obroke koji su puni esencijalnih vitamina i minerala kako biste se osjećali najbolje moguće.
                        <br></br><br></br>
                        Osim našeg plana obroka, također <strong>nudimo razne sportske aktivnosti</strong> koje su prilagođene vašoj razini kondicije i ciljevima.
                        <br></br><br></br>
                        <strong>Meditacija</strong> je također važan dio našeg programa. Vjerujemo da je zdrav um jednako važan kao i zdravo tijelo, a naše meditacijske sesije osmišljene su kako bi vam pomogle smanjiti stres, povećati fokus i razviti osjećaj unutarnjeg mira.
                        <br></br><br></br>
                        <strong>Mi ćemo vam pomoći</strong> u ostvarivanju vaših zdravstvenih ciljeva i životu vašeg najboljeg života.
                    </p>
                </div>
            </div>
            <h1 className="contactFormHeadTablet">Kontakt forma</h1>
            <div className="route__landingPage__contact">
                <img className="route__landingPage__contact__vector" src="images/what.png" alt="" />
                <div className="route__landingPage__contact__left">
                    <h1>Kontakt forma</h1>
                    <p>
                        Ukoliko imate bilo kakva pitanja ili brige u vezi naših programa, slobodno nas kontaktirajte. Popunite naš kontakt formular i mi ćemo se što prije javiti. Mi smo tu da Vam pomognemo i radujemo se pomoći Vam da postignete svoje zdravstvene ciljeve.
                    </p>
                    <div>
                        <span>
                            <p>Srebrenik, Ćehaje bb, 75350</p>
                            <small>Adresa</small>
                        </span>
                        <span>
                            <p>+387061 650 274</p>
                            <small>Broj telefona</small>
                        </span>
                        <span>
                            <small>Radno vrijeme</small>
                            <p>Ponedjeljak - petak 9h - 17h</p>
                        </span>
                        <span>
                            <small>Email</small>
                            <p>info@obscale.com</p>
                        </span>
                    </div>
                </div>
                <div className="route__landingPage__contact__right">
                    <div className="route__landingPage__contact__right__block">
                        <p>Vaše ime</p>
                        <div>
                            <img src="images/inputname.png" alt="" />
                            <input type='text' placeholder="Vaše ime" ref={nameRef} />
                        </div>
                    </div>
                    <div className="route__landingPage__contact__right__block">
                        <p>Broj telefona</p>
                        <div>
                            <img src="images/inputphone.png" alt="" />
                            <input type='text' placeholder="Broj telefona" ref={phoneRef} />
                        </div>
                    </div>
                    <div className="route__landingPage__contact__right__block">
                        <p>Poruka</p>
                        <div className="area">
                            <img src="images/inputmessage.png" alt="" />
                            <textarea placeholder="Poruka" />
                        </div>
                    </div>
                    <Button accent='#517047' value='Pošalji poruku' className='sendMessageButton' onClick={() => sendCRMmessage()} />
                    {message && <p style={{fontFamily: 'Raleway', color: '#517047', fontWeight: '700'}}>{message}</p>}
                </div>
            </div>
        </div>
        <Footer userInfo={props.userInfo} isLogged={props.isLogged} />
    </>
};

export default LandingPage;