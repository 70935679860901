import "./index.scss";
import React from "react";

import Spinner from "../customComponents/Spinner";
import Button from "../customComponents/Button";

import { animateBox } from "../../modules/componentAnimation";
import { useDispatch } from 'react-redux';
import * as timestampActions from "../../actions/timestampAction";
import * as backendModule from "../../modules/backendModule";
import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";
import axios from "axios";

const LoginForm = (props) => {
    const [spinner, setSpinner] = React.useState(false);

    const curDispatch = useDispatch();

    const usernameRef = React.useRef(null);
    const passwordRef = React.useRef(null);
    const msgRef = React.useRef(null);

    const loginHandler = () => {
        let data = {
            username: usernameRef.current.value,
            password: passwordRef.current.value
        };
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/login`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                curDispatch(timestampActions.setTimestamp());
                props.onClose();
            }
            else {
                msgRef.current.innerText = 'Incorrect username or password';
            }
        }).catch(() => {
            msgRef.current.value = "Server is not responding"
        }).finally(() => {
            setSpinner(false);
        });
    }

    return <div className="component__loginForm">
        <div className="component__loginForm__box">
            <img className="component__loginForm__box__close" src="images/close.svg" alt="" onClick={props.onClose} />
            <h1>Prijava</h1>
            <p>
                Nakon kupovine terapije, dobit ćete podatke za prijavu (korisničko ime i šifru). U slučaju da ne dobijete ili imate problema s ulaskom u sustav, molimo Vas da kontaktirate našu korisničku podršku na broj: +38766565232.
            </p>
            <div>
                <p>Korisničko ime</p>
                <span>
                    <img src="images/inputphone.png" alt="" />
                    <input ref={usernameRef} type='text' placeholder="Username" />
                </span>
            </div>
            <div>
                <p>Šifra</p>
                <span>
                    <img src="images/inputpassword.png" alt="" />
                    <input ref={passwordRef} type='password' placeholder="Šifra" />
                </span>
            </div>
            <Spinner style={{ display: spinner ? 'block' : 'none' }} align='center' color='#517047' />
            <Button style={{ display: !spinner ? 'block' : 'none' }} accent='#517047' value='Prijavite se' className='loginButton' onClick={() => loginHandler()} />
            <p ref={msgRef}></p>
        </div>
    </div>
}

const Header = (props) => {
    const [navbar, setNavbar] = React.useState(false);

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const curDispatch = useDispatch();

    const logout = () => {
        let data;
        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                curDispatch(timestampActions.setTimestamp());
                animateNavigate('/');
            }
            else {

            }
        }).catch(() => {

        });
    }

    return <div className="component__header">
        <img src="images/logo.svg" alt="" onClick={() => animateNavigate('/')} style={{cursor: 'pointer'}} />
        <div className="component__header__menu">
            {props.logged && props.userInfo?.Flags?.isAdmin && <p onClick={() => { animateNavigate('/adminpanel') }}>Admin panel</p>}
            {props.logged && !props.userInfo?.Flags?.isAdmin && <p onClick={() => { animateNavigate('/vasprogram') }}>Vaš program</p>}
            <p onClick={() => { window.location.hash !== '#/' ? animateNavigate('/') : document.querySelector('.route__landingPage__hero').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }) }}>Početna</p>
            <p onClick={() => { window.location.hash !== '#/' ? animateNavigate('/') : document.querySelector('.route__landingPage__about').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }) }}>O nama</p>
            <p onClick={() => { window.location.hash !== '#/' ? animateNavigate('/') : document.querySelector('.route__landingPage__contact').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }) }}>Kontaktirajte nas</p>
        </div>
        <div className="component__header__profile">
            {props.logged && !props.userInfo?.Flags?.isAdmin && <span><img src="images/profile.svg" alt="" /><p>{props.userInfo?.Username}</p></span>}
            <p onClick={(e) => { !props.logged ? animateBox(e, <LoginForm navigate={animateNavigate} />) : logout() }}>{!props.logged ? 'Prijava' : 'Odjava'}</p>
        </div>
        <img className="component__header__hamburger" src="images/hamburger.svg" alt="" onClick={() => setNavbar(n => !n)} />
        {navbar && <div className="component__header__mobile">
            <div>
                <img className="closeMobileNav" src="images/close.svg" alt="" onClick={() => setNavbar(false)} />
                <img className="logoMobile" src="images/mobilelogo.svg" alt="" />
                {props.logged && props.userInfo?.Flags?.isAdmin && <p onClick={() => { animateNavigate('/adminpanel'); setNavbar(false) }}>Admin panel</p>}
                {props.logged && <p onClick={() => { animateNavigate('/vasprogram'); setNavbar(false) }}>Vaš program</p>}
                <p onClick={() => { window.location.hash !== '#/' ? animateNavigate('/') : document.querySelector('.route__landingPage__hero').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }); setNavbar(false) }}>Početna</p>
                <p onClick={() => { window.location.hash !== '#/' ? animateNavigate('/') : document.querySelector('.route__landingPage__about').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }); setNavbar(false) }}>O nama</p>
                <p onClick={() => { window.location.hash !== '#/' ? animateNavigate('/') : document.querySelector('.route__landingPage__contact').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }); setNavbar(false) }}>Kontaktirajte nas</p>
                {props.logged && !props.userInfo?.Flags?.isAdmin && <span><img src="images/profile.svg" alt="" /><p>{props.userInfo?.Username}</p></span>}
                <p onClick={(e) => { !props.logged ? animateBox(e, <LoginForm navigate={animateNavigate} />) : logout(); setNavbar(false) }}>{!props.logged ? 'Prijava' : 'Odjava'}</p>
                <span>
                    <img src='images/linkedin.svg' alt='' />
                    <img src='images/messenger.svg' alt='' />
                </span>
            </div>
        </div>}
    </div>
};

export default Header;