import React from 'react';
import "./index.scss";

import { animateBox } from "../../modules/componentAnimation";
import * as backendModule from "../../modules/backendModule";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Button from '../../components/customComponents/Button';
import MealsTable from '../../components/MealsTable';
import Spinner from '../../components/customComponents/Spinner';

import axios from 'axios';
import moment from 'moment';

export default function PreparedMeals() {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [filters, setFilters] = React.useState([]);
    const [preparedMeals, setPreparedMeals] = React.useState([]);

    const getAllPreparedMeals = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/preparedMeals/getAllPreparedMeals`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setPreparedMeals(res.data);
            }
            else {
                setErrorMessage('Došlo je do greške...');
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        });
    }

    const addPreparedMealsToTable = () => {
        if (preparedMeals.data) {
            return preparedMeals?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.PatientType}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Breakfast}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Lunch}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Dinner}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'rgb(95, 132, 83)' }}>Are you sure?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='rgb(95, 132, 83)' value='YES' onClick={() => deletePreparedMeal(item.ID)} />
                                            <Button accent='rgb(95, 132, 83)' value='NO' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                },
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Obroci", triggerDropdown: true, onClick: e => {
                                    animateBox(e, <MealsPreviewModal curMeals={{
                                        Breakfast: item.Breakfast,
                                        Lunch: item.Lunch,
                                        Dinner: item.Dinner
                                    }} />);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Uredi", triggerDropdown: true, onClick: e => {
                                    animateBox(e, <EditPreparedMealModal curPreparedMeal={
                                        {
                                            ID: item.ID,
                                            PatientType: item.PatientType,
                                            Breakfast: item.Breakfast,
                                            Lunch: item.Lunch,
                                            Dinner: item.Dinner
                                        }
                                    } getAll={getAllPreparedMeals} />);
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    const deletePreparedMeal = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/preparedMeals/removePreparedMeal`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllPreparedMeals();
            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllPreparedMeals();
    }, [filters]);

    return (
        <div className='route__preparedMeals'>
            <div className='route__preparedMeals__header'>
                <div className='route__preparedMeals__header__button' onClick={(e) => animateBox(e, <AddNewPreparedMealModal getAll={getAllPreparedMeals} />)}>
                    <img src='images/plus.svg' alt='' />
                    <p>Pripremi novi obrok</p>
                </div>
            </div>
            {!errorMessage && <div className='route__meals__body'>
                <FilteredCustomTable
                    id='table'
                    accent='#5f8453'
                    theme='light'
                    headers={['ID', 'Tip pacijenta', 'Doručak', 'Ručak', 'Večera', 'Uređeno']}
                    filters={[
                        { name: "ID", friendlyName: "ID", type: "number" },
                        { name: "PatientType", friendlyName: "Tip pacijenta", type: "string" },
                    ]}
                    filterCB={f => setFilters(f)}
                    data={preparedMeals.data?.length > 0 ? addPreparedMealsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                />
            </div>}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    )
}

const AddNewPreparedMealModal = (props) => {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [spinner, setSpinner] = React.useState(false);
    const [breakfast, setBreakfast] = React.useState(null);
    const [lunch, setLunch] = React.useState(null);
    const [dinner, setDinner] = React.useState(null);

    const patientTypeRef = React.useRef(null);

    const addPreparedMeal = (close) => {
        setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/preparedMeals/addPreparedMeal`,
            data: {
                PatientType: patientTypeRef.current.value,
                Breakfast: breakfast.ID,
                Lunch: lunch.ID,
                Dinner: dinner.ID
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === 'ok') {
                props.getAll();
                close();
            }
            else {
                setErrorMessage('Sva polja moraju biti popunjena!');
            }
        }).catch(() => {
            setErrorMessage('Server ne reagira!');
        }).finally(() => {
            setSpinner(false);
        });
    }

    return <div className='component__addNewPreparedMealModal'>
        {!spinner && <div className='component__addNewPreparedMealModal__wrapper'>
            <img className='component__addNewMealModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
            <input type='text' placeholder='Tip pacijenta' ref={patientTypeRef} />
            <Button className='component__addNewMealModal__wrapper__button' value={breakfast ? `Doručak: ${breakfast.Name}` : 'Izaberi doručak'} accent='#517047' onClick={(e) => animateBox(e, <MealsTable value='breakfast' set={setBreakfast} />)} />
            <Button className='component__addNewMealModal__wrapper__button' value={lunch ? `Ručak: ${lunch.Name}` : 'Izaberi ručak'} accent='#517047' onClick={(e) => animateBox(e, <MealsTable value='lunch' set={setLunch} />)} />
            <Button className='component__addNewMealModal__wrapper__button' value={dinner ? `Večera: ${dinner.Name}` : 'Izaberi večeru'} accent='#517047' onClick={(e) => animateBox(e, <MealsTable value='dinner' set={setDinner} />)} />
            <Button className='component__addNewMealModal__wrapper__button addMeal' style={{ fontWeight: '700' }} value='Dodaj' accent='#517047' onClick={() => addPreparedMeal(props.onClose)} />
            {errorMessage && <p>{errorMessage}</p>}
        </div>}
        {spinner && <Spinner color='#517047' align='center' />}
    </div>
}

const MealsPreviewModal = (props) => {
    const [breakfast, setBreakfast] = React.useState(null);
    const [lunch, setLunch] = React.useState(null);
    const [dinner, setDinner] = React.useState(null);
    const [spinner, setSpinner] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState(null);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/meals/getAllMeals`,
            data: {
                filters: [
                    { name: 'ID', op: 'eq', value: props.curMeals?.Breakfast },
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setBreakfast(res.data.data[0].Name);
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        }).finally(() => {
            setSpinner(false);
        });
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/meals/getAllMeals`,
            data: {
                filters: [
                    { name: 'ID', op: 'eq', value: props.curMeals?.Lunch },
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setLunch(res.data.data[0].Name);
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        }).finally(() => {
            setSpinner(false);
        });
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/meals/getAllMeals`,
            data: {
                filters: [
                    { name: 'ID', op: 'eq', value: props.curMeals?.Dinner },
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setDinner(res.data.data[0].Name);
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        }).finally(() => {
            setSpinner(false);
        });
    }, [props.curMeals]);

    return <div className='component__mealsPreviewModal'>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        {!errorMessage && <div className='component__mealsPreviewModal__wrapper'>
            <img src='images/close.svg' alt='' onClick={props.onClose} />
            {
                !spinner && breakfast && <div style={{ background: 'rgb(111 163 94)' }}>
                    <p><strong>Doručak:</strong> {breakfast}</p>
                </div>
            }
            {
                !spinner && lunch && <div style={{ background: '#90b982' }}>
                    <p><strong>Ručak:</strong> {lunch}</p>
                </div>
            }
            {
                !spinner && dinner && <div style={{ background: 'rgb(111 163 94)' }}>
                    <p><strong>Večera:</strong> {dinner}</p>
                </div>
            }
            {
                spinner && <Spinner color='#517047' align='center' />
            }
        </div>}
    </div>
}

const EditPreparedMealModal = (props) => {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [spinner, setSpinner] = React.useState(false);
    const [breakfast, setBreakfast] = React.useState(null);
    const [lunch, setLunch] = React.useState(null);
    const [dinner, setDinner] = React.useState(null);

    const patientTypeRef = React.useRef(null);

    const savePreparedMeal = (close) => {
        setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/preparedMeals/editPreparedMeal`,
            data: {
                ID: props.curPreparedMeal?.ID,
                PatientType: patientTypeRef.current.value,
                Breakfast: breakfast.ID,
                Lunch: lunch.ID,
                Dinner: dinner.ID
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === 'ok') {
                props.getAll();
                close();
            }
            else {
                setErrorMessage('Sva polja moraju biti popunjena!');
            }
        }).catch(() => {
            setErrorMessage('Server ne reagira!');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/meals/getAllMeals`,
            data: {
                filters: [
                    { name: 'ID', op: 'eq', value: props.curPreparedMeal?.Breakfast },
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setBreakfast({Name: res.data.data[0].Name, ID: res.data.data[0].ID});
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        }).finally(() => {
            setSpinner(false);
        });
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/meals/getAllMeals`,
            data: {
                filters: [
                    { name: 'ID', op: 'eq', value: props.curPreparedMeal?.Lunch },
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setLunch({Name: res.data.data[0].Name, ID: res.data.data[0].ID});
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        }).finally(() => {
            setSpinner(false);
        });
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/meals/getAllMeals`,
            data: {
                filters: [
                    { name: 'ID', op: 'eq', value: props.curPreparedMeal?.Dinner },
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setDinner({Name: res.data.data[0].Name, ID: res.data.data[0].ID});
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        }).finally(() => {
            setSpinner(false);
        });
    }, [props.curPreparedMeal]);

    return <div className='component__addNewPreparedMealModal'>
        {!spinner && <div className='component__addNewPreparedMealModal__wrapper'>
            <img className='component__addNewMealModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
            <input type='text' placeholder='Tip pacijenta' ref={patientTypeRef} defaultValue={props.curPreparedMeal?.PatientType} />
            <Button className='component__addNewMealModal__wrapper__button' value={breakfast ? `Doručak: ${breakfast.Name}` : 'Izaberi doručak'} accent='#517047' onClick={(e) => animateBox(e, <MealsTable value='breakfast' set={setBreakfast} />)} />
            <Button className='component__addNewMealModal__wrapper__button' value={lunch ? `Ručak: ${lunch.Name}` : 'Izaberi ručak'} accent='#517047' onClick={(e) => animateBox(e, <MealsTable value='lunch' set={setLunch} />)} />
            <Button className='component__addNewMealModal__wrapper__button' value={dinner ? `Večera: ${dinner.Name}` : 'Izaberi večeru'} accent='#517047' onClick={(e) => animateBox(e, <MealsTable value='dinner' set={setDinner} />)} />
            <Button className='component__addNewMealModal__wrapper__button addMeal' style={{ fontWeight: '700' }} value='Sačuvaj' accent='#517047' onClick={() => savePreparedMeal(props.onClose)} />
            {errorMessage && <p style={{color: 'black'}}>{errorMessage}</p>}
        </div>}
        {spinner && <Spinner color='#517047' align='center' />}
    </div>
}