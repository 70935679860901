import React from 'react';
import "./index.scss";

import { FilteredCustomTable } from '../customComponents/Table';
import Spinner from '../customComponents/Spinner';

import * as backendModule from "../../modules/backendModule";

import axios from 'axios';
import moment from 'moment';

export default function MealsTable(props) {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [meals, setMeals] = React.useState([]);

    const getAllMeals = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/meals/getAllMeals`,
            data: {
                filters: [
                    { name: 'MealType', op: 'eq', value: props.value }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setMeals(res.data);
            }
            else {
                setErrorMessage('Došlo je do greške...');
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        });
    }

    const addMealsToTable = () => {
        if (meals.data) {
            return meals?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.MealType}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Prikaži sliku", triggerDropdown: true, onClick: e => {
                                    window.open(`/#/image/${String(item.Image).replace(`${backendModule.backendURL}/staticContent/images/`, '')}`, '_blank');
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Opis", triggerDropdown: true, triggerData: e => {
                                    return (<div>
                                        <p>{item.Description ? item.Description : 'Nema opisa'}</p>
                                    </div>);
                                },
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Odaberi", triggerDropdown: true, triggerData: e => {
                                    props.set({ID: item.ID, Name: item.Name});
                                    props.onClose();
                                },
                            },
                        ]
                    }
                ];
            });
        }
    }

    React.useEffect(() => {
        getAllMeals();
    }, []);

    return (
        <div className='component__mealsTable'>
            {!errorMessage && <div className='component__mealsTable__body'>
                <img src='images/close.svg' alt='' onClick={props.onClose} />
                <FilteredCustomTable
                    id='table'
                    accent='#5f8453'
                    theme='light'
                    headers={['ID', 'Naziv', 'Vrsta obroka', 'Uređeno']}
                    data={meals.data?.length > 0 ? addMealsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                />
            </div>}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    )
}
