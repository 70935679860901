import React from 'react';
import "./index.scss";

import { animateBox } from "../../modules/componentAnimation";
import * as backendModule from "../../modules/backendModule";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Dropdown from '../../components/customComponents/Dropdown';
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';
import ViewPhotoModal from '../../components/ViewPhotoModal';

import axios from 'axios';
import moment from 'moment';

const mealTypes = [
  { name: 'Doručak', value: 'breakfast' },
  { name: 'Ručak', value: 'lunch' },
  { name: 'Večera', value: 'dinner' },
];

export default function Meals() {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [filters, setFilters] = React.useState([]);
  const [meals, setMeals] = React.useState([]);

  const getAllMeals = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/meals/getAllMeals`,
      data: {
        filters: filters
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setMeals(res.data);
      }
      else {
        setErrorMessage('Došlo je do greške...');
      }
    }).catch((e) => {
      setErrorMessage('Problem sa serverom...');
    });
  }

  const addMealsToTable = () => {
    if (meals.data) {
      return meals?.data?.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.MealType}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              {
                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                  return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: 'rgb(95, 132, 83)' }}>Are you sure?</p>
                    <br></br>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Button style={{ marginRight: '10px' }} accent='rgb(95, 132, 83)' value='YES' onClick={() => deleteMeal(item.ID)} />
                      <Button accent='rgb(95, 132, 83)' value='NO' onClick={c => { e() }} />
                    </div>
                  </div>);
                },
              },
              {
                keyID: String(item.ID), type: "button", text: "Prikaži sliku", triggerDropdown: true, onClick: e => {
                  animateBox(e, <ViewPhotoModal src={String(item.Image).replace(`${backendModule.backendURL}/staticContent/images/`, '')} />);
                }
              },
              {
                keyID: String(item.ID), type: "button", text: "Opis", triggerDropdown: true, triggerData: e => {
                  return (<div>
                    <p>{item.Description ? item.Description : 'Nema opisa'}</p>
                  </div>);
                },
              },
              {
                keyID: String(item.ID), type: "button", text: "Sastojci", triggerDropdown: true, triggerData: e => {
                  return (<div>
                    <p>{item.Ingredients}</p>
                  </div>);
                },
              },
              {
                keyID: String(item.ID), type: "button", text: "Uredi", triggerDropdown: true, onClick: e => {
                  animateBox(e, <EditMealModal curMeal={
                    {
                      ID: item.ID,
                      Name: item.Name,
                      Description: item.Description,
                      Ingredients: item.Ingredients,
                      MealType: item.MealType,
                      Calories: item.Calories
                    } 
                  } getAll={getAllMeals} />);
                }
              },
            ]
          }
        ];
      });
    }
  }

  const deleteMeal = (id) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/meals/removeMeal`,
      data: {
        ID: id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getAllMeals();
      }
    }).catch(() => {

    });
  }

  React.useEffect(() => {
    getAllMeals();
  }, [filters]);

  return (
    <div className='route__meals'>
      <div className='route__meals__header'>
        <div className='route__meals__header__button' onClick={(e) => animateBox(e, <AddNewMealModal getAll={getAllMeals} />)}>
          <img src='images/plus.svg' alt='' />
          <p>Dodaj novi obrok</p>
        </div>
      </div>
      {!errorMessage && <div className='route__meals__body'>
        <FilteredCustomTable
          id='table'
          accent='#5f8453'
          theme='light'
          headers={['ID', 'Naziv', 'Vrsta obroka', 'Uređeno']}
          filters={[
            { name: "ID", friendlyName: "ID", type: "number" },
            { name: "Name", friendlyName: "Naziv", type: "string" },
            { name: "MealType", friendlyName: "Vrsta obroka", type: "string" },
          ]}
          filterCB={f => setFilters(f)}
          data={meals.data?.length > 0 ? addMealsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
        />
      </div>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  )
}

const AddNewMealModal = (props) => {
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [mealType, setMealType] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);

  const nameRef = React.useRef(null);
  const descriptionRef = React.useRef(null);
  const ingredientsRef = React.useRef(null);
  const caloriesRef = React.useRef(null);

  const addMeal = (close) => {
    if (!selectedImage) {
      setErrorMessage('Molimo izaberite sliku!');
    }

    setSpinner(true);

    let data = {
      Name: nameRef.current.value,
      Description: descriptionRef.current.value,
      Image: selectedImage,
      Ingredients: ingredientsRef.current.value,
      MealType: mealType,
      Calories: caloriesRef.current.value
    }

    let fd = new FormData();
    for (let key in data) {
        fd.append(key, data[key]);
    }

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/meals/addMeal`,
      data: fd,
      ...backendModule.axiosConfig,
      headers: {
        ...backendModule.axiosConfig.headers,
        'Content-Type': 'multipart/form-data'
      },
    }).then(res => {
      if (res.data.status === 'ok') {
        props.getAll();
        close();
      }
      else {
        setErrorMessage('Sva polja moraju biti popunjena!');
      }
    }).catch(() => {
      setErrorMessage('Server ne reagira!');
    }).finally(() => {
      setSpinner(false);
    });
  }

  return <div className='component__addNewMealModal'>
    {!spinner && <div className='component__addNewMealModal__wrapper'>
      <img className='component__addNewMealModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
      <input type='text' placeholder='Naziv' ref={nameRef} />
      <textarea type='text' placeholder='Opis' ref={descriptionRef} ></textarea>
      <textarea type='text' placeholder='Sastojci' ref={ingredientsRef} ></textarea>
      <input type='text' placeholder='Kalorije' ref={caloriesRef} />
      <Dropdown accent='#517047' inlinePlaceholder='Vrsta obroka'
        data={mealTypes}
        onChange={e => setMealType(e?.value)}
      />
      <div className='component__addNewMealModal__wrapper__imginput'>
        <input type="file" accept="image/*" id="imageInput" onChange={(e) => {
          setSelectedImage(e.target.files[0]);
        }} />
        <label for="imageInput" class="image-button"><img src="images/imginput.svg" class="image-preview" />{selectedImage ? selectedImage.name : 'Izaberi sliku'}</label>
      </div>
      <Button className='component__addNewMealModal__wrapper__button addMeal' style={{ fontWeight: '700' }} value='Dodaj' accent='#517047' onClick={() => addMeal(props.onClose)} />
      {errorMessage && <p>{errorMessage}</p>}
    </div>}
    {spinner && <Spinner color='#517047' />}
  </div>
}

const EditMealModal = (props) => {
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [mealType, setMealType] = React.useState(props.curMeal?.MealType);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);

  const nameRef = React.useRef(null);
  const descriptionRef = React.useRef(null);
  const ingredientsRef = React.useRef(null);
  const caloriesRef = React.useRef(null);

  const saveMeal = (close) => {
    setSpinner(true);

    let data = {
      ID: props.curMeal?.ID,
      Name: nameRef.current.value,
      Description: descriptionRef.current.value,
      Image: selectedImage,
      Ingredients: ingredientsRef.current.value,
      MealType: mealType,
      Calories: caloriesRef.current.value
    }

    let fd = new FormData();
    for (let key in data) {
      fd.append(key, data[key]);
    }

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/meals/editMeal`,
      data: fd,
      ...backendModule.axiosConfig,
      headers: {
        ...backendModule.axiosConfig.headers,
        'Content-Type': 'multipart/form-data'
      },
    }).then(res => {
      if (res.data.status === 'ok') {
        props.getAll();
        close();
      }
      else {
        setErrorMessage('Sva polja moraju biti popunjena!');
      }
    }).catch(() => {
      setErrorMessage('Server ne reagira!');
    }).finally(() => {
      setSpinner(false);
    });
  }

  return <div className='component__editMealModal'>
    {!spinner && <div className='component__editMealModal__wrapper'>
      <img className='component__editMealModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
      <input type='text' placeholder='Naziv' ref={nameRef} defaultValue={props.curMeal?.Name} />
      <textarea type='text' placeholder='Opis' ref={descriptionRef} defaultValue={props.curMeal?.Description} ></textarea>
      <textarea type='text' placeholder='Sastojci' ref={ingredientsRef} defaultValue={props.curMeal?.Ingredients} ></textarea>
      <input type='text' placeholder='Kalorije' ref={caloriesRef} defaultValue={props.curMeal?.Calories} />
      <Dropdown accent='#517047' inlinePlaceholder='Vrsta obroka'
        data={mealTypes}
        onChange={e => setMealType(e?.value)}
        selected={mealTypes.map(e => e.value).indexOf(props?.curMeal?.MealType)}
      />
      <div className='component__editMealModal__wrapper__imginput'>
        <input type="file" accept="image/*" id="imageInput" onChange={(e) => {
          setSelectedImage(e.target.files[0]);
        }} />
        <label for="imageInput" class="image-button"><img src="images/imginput.svg" class="image-preview" />{selectedImage ? selectedImage.name : 'Izaberi sliku'}</label>
      </div>
      <Button className='component__editMealModal__wrapper__button addMeal' style={{ fontWeight: '700' }} value='Sačuvaj' accent='#517047' onClick={() => saveMeal(props.onClose)} />
      {errorMessage && <p style={{color: 'black'}}>{errorMessage}</p>}
    </div>}
    {spinner && <Spinner color='#517047' />}
  </div>
}